import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PubquizPage = () => (
  <Layout>
    <SEO title="Pubquiz" />
    <p>Waerde lords,</p>
    <p>Uiteraard kunnen we bij Claymore kennis zeer apprecieren. In Februari zullen we een pubquiz houden waarbij deze belangrijke deugd van de lords aan de kaak wordt gesteld. Er zullen vragen zijn over algemene kennis, maar er zullen vooral ook custom vragen zijn over interne acts van Claymore door de jaren heen. Dit is hét uitgelezen moment om terug te denken aan klassieke acts of kennis te maken met nieuwe (trieste) verhalen. Komt allen! En uiteraard is er een mooie prijs voor het winnende team.</p>
    <p>
      Locatie: TBA<br />
      Datum: Eind Februari
    </p>
  </Layout>
)

export default PubquizPage
